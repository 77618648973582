<template>
    <div class="contact-box" @click="toggleContact">
        <div class="contact-info" v-if="showContact">
            <a href="#"><i class="iconfont icon-weixin"></i></a>
            <a href="#"><i class="iconfont icon-QQ"></i></a>
            <a href="#"><i class="iconfont icon-weibo"></i></a>
            <a href="#"><i class="iconfont icon-douyin"></i></a>
        </div>
        <div class="contact-icon" v-else>
            <i class="iconfont icon-fenxiang"></i>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showContact: false, // 控制联系方式的显示与隐藏
        };
    },
    methods: {
        toggleContact() {
            this.showContact = !this.showContact; // 切换显示状态
        },
    },
};
</script>

<style scoped>
@import url('https://at.alicdn.com/t/c/font_4738555_u5kgi71hexd.css?spm=a313x.manage_type_myprojects.i1.9.1c663a81zqxzqc&file=font_4738555_u5kgi71hexd.css');

.contact-box {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #1E2021;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #535353;
}

.contact-box:hover {
    background-color: #2e2e2e;
    /* 悬停时变色 */
}

.contact-info {
    display: block;
    padding: 10px 5px;
}

.contact-icon {
    display: block;
}

.contact-info i {
    border: 1px solid #fff;
    padding: 8px;
    border-radius: 50%;
    margin: 0 5px;
    transition: .2s;
}

.contact-info i:hover {
    background: #3a3a3a;
}
</style>