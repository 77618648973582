<template>
    <div class="head-div"></div>
    <div class="thirdlevel">
        <div v-if="categoryData.length" class="secondary-thirdlevel">
            <ul>
                <li v-for="(item, index) in categoryData" :key="index" class="thirdlevel-title">
                    <div>
                        <a href="#" @click="handleClick(item.brand)">
                            <p>{{ item.brand }}</p>
                        </a>
                    </div>
                    <a href="#" @click="handleClick(item.ids)" id="thirdlevel-thumbnail">
                        <img :src="item.image" alt="">
                    </a>
                    <div class="secondary-price">
                        <span>￥{{ item.price }} ≈ ${{ item.dao }}</span>
                        <span class="iconfont icon-yanjing">{{ item.views }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else>
            <p>没有找到相关商品。</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            categoryData: null, // 存储根据类别获取的数据
            categoryTitle: ''
        };
    },
    created() {
        this.fetchCommodities();
        const category = this.$route.params.category; // 获取路由参数
        this.categoryTitle = category.charAt(0).toUpperCase() + category.slice(1); // 将类别首字母大写
        this.fetchCategoryData(category); // 根据类别获取数据
    },
    methods: {
        handleClick(title) {
            this.$router.push({ name: 'ProductDetails', params: { title } });
        },

        fetchCategoryData(category) {
            // 模拟数据
            const data = {};
            this.categoryData = data[category.toLowerCase()] || []; // 根据类别设置数据，转换为小写以匹配
        },

        async fetchCommodities() {
            try {
                const response = await axios.get(`http://ldy.nanaidc.com/api/template_shop?filter={"category id":"7"}`);
                // 将接口返回的数据映射到 categoryData
                this.categoryData = response.data.data.rows.map(item => ({
                    brand: item.title, // 渲染 title
                    image: item.images,   // 渲染 image
                    price: item.moeny,
                    dao: item.bi.lv,
                    views: item.views,
                    ids: item.id,
                    row: item.row,
                }));
            } catch (error) {
                console.error('Error fetching commodities:', error);
            }
        }
    }
}
</script>

<style>
@import url('https://at.alicdn.com/t/c/font_4738555_5c25yan1xcq.css?spm=a313x.manage_type_myprojects.i1.9.1c663a81zfZBik&file=font_4738555_5c25yan1xcq.css');

.thirdlevel div p {
    margin: 30px 0;
}
</style>