<template>
  <div class="heards">
    <div class="head-logoUser">
      <div>
        <a href=""><i class="iconfont icon-zhinengxuanxie_qiaodan"></i></a>
        <a href=""><i class="iconfont icon-converse"></i></a>
      </div>
      <ul class="head-user">
        <li><a href="">帮助</a>|</li>
        <li><a href="">立即加入</a>|</li>
        <li><a href="">登录</a></li>
      </ul>
    </div>
    <div class="sort">
      <a href="#" @click.prevent="goToHome"><img src="../assets/image/nike.svg" alt="Nike Logo" /></a>
      <ul class="sort-commodity head-user">
        <li v-for="(item, index) in commodities" :key="index" @mouseenter="showDropdown(index)"
          @mouseleave="hideDropdown">
          <a href="#" class="sort-title" @click.prevent="goToCategory({ pid: item.pid })">{{ item.title }}</a>
          <transition name="fade">
            <div v-if="activeIndex === index" class="drop-downMenu" @mouseenter="showDropdown(index)"
              @mouseleave="hideDropdown">
              <ul class="drop-list">
                <li v-for="(subItem, subIndex) in item.items" :key="subIndex">
                  <a id="drop-title" href="#" @click.prevent="goToCategory({ pid: subItem.pid })">{{ subItem.title
                    }}</a>
                  <ul class="drop-ul">
                    <li id="drop-content" v-for="(content, cIndex) in subItem.contents" :key="cIndex">
                      <a href="#" id="drop-a" @click.prevent="goToCategory({ pid: content.pid })">{{ content.title
                        }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </transition>
        </li>
      </ul>
      <div class="sort-search">
        <div><i class="iconfont icon-sousuo"></i></div>
        <input type="text" placeholder="搜索" />
      </div>
      <div class="sort-shopping">
        <div>
          <a href="#"><i class="iconfont icon-aixin"></i></a>
        </div>
        <div>
          <a href="#"><i class="iconfont icon-gouwuche"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    commodities: {
      type: Array,
      required: true
    },
    activeIndex: {
      type: Number,
      default: null
    },
  },
  methods: {
    goToHome() {
      this.$emit('navigate', 'Home'); // 触发 navigate 事件，传递 'Home'
    },
    showDropdown(index) {
      this.$emit('update:activeIndex', index);
    },
    hideDropdown() {
      this.$emit('update:activeIndex', null);
    },
    goToCategory(category) {
      this.$emit('navigate', category);
    }
  },
  emits: ['update', 'navigate']
}
</script>

<style scoped>
/* 在这里添加样式 */
</style>