<template>
    <div class="head-div"></div>
    <div v-if="products" class="product">
        <div class="product-buy">
            <ImageZoom :title="products.title" :images="products.images" ref="imageZoom" />
            <div class="product-details">
                <div class="product-title">
                    <p>{{ products.title }}</p>
                </div>
                <div class="product-moeny">
                    <p>CNY ￥{{ products.moeny }}</p>
                </div>
                <div class="product-size">
                    <p>Size:</p>
                    <ul>
                        <li v-for="size in sizes" :key="size" :id="selectedSize === size ? 'product-size-option' : ''"
                            @click="selectSize(size)">
                            {{ size }}
                        </li>
                    </ul>
                </div>
                <div class="product-sku">
                    <p>Shape:</p>
                    <div v-for="(item, index) in slidesGroupSku" :key="index">
                        <img :src="item.image" alt="Product Image" @click="switchImage(item.image)">
                    </div>
                </div>
                <div class="quantity-selector" id="quantity-selector">
                    <p>Quantity:</p>
                    <div>
                        <button @click="decrement" :disabled="quantity <= 1">-</button>
                        <input type="number" v-model="quantity" min="1" />
                        <button @click="increment">+</button>
                    </div>
                </div>
                <div>
                    <a href="#">
                        <i class="iconfont icon-gouwuchetianjia"></i>
                        Price is Below Retail
                    </a>
                </div>
                <div>
                    <button @click="addToCart">Buy Now</button>
                </div>
            </div>
        </div>
        <div class="product-type">
            <div>
                <h3>其他类型</h3>
                <swiper :modules="[Virtual]" :slides-per-view="4" :space-between="20" class="carousel" navigation>
                    <swiper-slide v-for="(slide, index) in slidesGroup" :key="index" :virtualIndex="index">
                        <a href="#"><img :src="slide.images" alt=""></a>
                        <p>品牌: {{ slide.title }}</p>
                        <p>价格: ￥{{ slide.moeny }}</p>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
    <div v-else class="thirdlevel">
        <p>商品未找到。</p>
    </div>
</template>

<script>
// 导入 ImageZoom 组件
import ImageZoom from '../module/ImageZoom.vue';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { ref } from 'vue';

export default {
    components: {
        Swiper,
        SwiperSlide,
        ImageZoom, // 注册组件
    },
    data() {
        return {
            products: {}, // 存储所有产品
            selectedSize: '',
            sizes: [], // 尺码数组
            quantity: 1, // 商品数量
            slidesGroupSku: [], // 存储图片数组
            currentImage: '',
        };
    },
    setup() {
        // 图片数组
        const slidesGroup = ref([]); // 使用 ref 以便在响应式系统中跟踪变化

        return {
            slidesGroup,
            Virtual,
        };
    },

    created() {
        const ids = this.$route.params.title; // 获取路由参数
        this.fetchProductData(ids); // 根据 ID 获取商品数据
    },

    methods: {
        increment() {
            this.quantity += 1; // 增加数量
        },
        decrement() {
            if (this.quantity > 1) {
                this.quantity -= 1; // 减少数量
            }
        },
        selectSize(size) {
            this.selectedSize = size; // 更新选中的尺码
        },
        async fetchProductData(ids) {
            try {
                const response = await axios.get(`http://ldy.nanaidc.com/api/template_shop/view?ids=${ids}`);
                this.products = response.data.data; // 将获取到的产品数据存储到 products 中

                // 解析 sku_array
                const skuArray = JSON.parse(response.data.data.sku_array); // 解析 JSON 字符串为对象

                // 提取图片和标题
                this.slidesGroupSku = skuArray.map(item => {
                    return {
                        image: `http://ldy.nanaidc.com${item.image}` // 拼接完整的图片 URL
                    };
                });

                // 设置默认显示的图片
                if (this.slidesGroupSku.length > 0) {
                    this.currentImage = this.slidesGroupSku[0].image; // 使用第一张图片作为默认显示
                }

                console.log(response); // 打印产品数据以便调试
            } catch (error) {
                console.error('Error fetching commodities:', error);
                this.products = {}; // 在发生错误时清空产品列表
                this.slidesGroupSku = []; // 发生错误时清空 slidesGroupSku
                this.currentImage = ''; // 清空当前图片
            }
        },
        addToCart() {
            // 添加到购物车的逻辑
            console.log(`Added ${this.products.title} to cart.`);
        },
        switchImage(image) {
            this.currentImage = image; // 切换当前图片
            this.$refs.imageZoom.switchImage(image); // 切换放大器中的图片
        },
        goToSecondary(category) {
            this.$router.push({ name: 'Secondary', params: { category } });
        }
    }

};
</script>

<style scoped>
@import url('https://at.alicdn.com/t/c/font_4738555_9lowe7f7wi.css?spm=a313x.manage_type_myprojects.i1.9.1c663a81MbzYSm&file=font_4738555_9lowe7f7wi.csss');

.swiper-container {
    width: 100%;
    height: 200px;
}

.swiper-slide img {
    width: 100%;
    height: 260px;
    object-fit: cover;
}

.thirdlevel p {
    margin: 30px 0;
}
</style>
