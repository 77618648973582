<template>
  <div class="head-div"></div>
  <div class="secondary">
    <div class="secondary-headImg">
      <img src="https://xcimg.szwego.com/wsxcWeb/24ca8593/o_1i7oj4hu21nq1s0614sc1of2plc.jpg" alt="">
    </div>
    <div class="secondary-thumbnail">
      <ul>
        <li v-for="(item, index) in products" :key="index">
          <a :href="`/SeconDary/${item.category}`" @click.prevent="goToSecondary(item.category)">
            <img :src="item.image" alt="">
            <p>{{ item.title }}</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AllProducts',
  data() {
    return {
      products: []
    };
  },
  created() {
    this.fetchCommodities();
  },
  watch: {
    '$route'() {
      this.fetchCommodities(); // 路由变化时重新获取数据
    }
  },
  methods: {
    async fetchCommodities() {
      const pidValue = this.$route.query.pid || '';

      try {
        const response = await axios.get(`http://ldy.nanaidc.com/api/category?search=${pidValue}`);
        this.products = response.data.data.data.map(item => ({
          title: item.name,
          category: item.name,
          image: item.image,
        }));
      } catch (error) {
        console.error('Error fetching commodities:', error);
      }
    },
    goToSecondary(category) {
      this.$router.push({ name: 'Secondary', params: { category } });
    }
  },
}
</script>

<style scoped>
@import url('/src/assets/css/secondary.css');
</style>